



































import Vue, {PropType} from "vue";
import {BankAccountData} from "@/types";

export default Vue.extend({
  name: "bank-account-block" as string,

  props: {
    data: Object as PropType<BankAccountData>,
    selectedAccount: Number as PropType<number>,
    selectable: Boolean as PropType<boolean>
  },

  data() {
    return {
      selectedBankAccount: this.selectedAccount
    }
  },

  methods: {
    setSelectedBankAccount(accountId: number): void {
      if (this.selectable) {
        this.selectedBankAccount = accountId;
        this.$emit("change", { paymentId: accountId, paymentType: 2 })
      }
    }
  },

  watch: {
    selectedAccount: {
      handler(value: number) {
        if (value === this.data.id) {
          this.selectedBankAccount = value;
        } else {
          this.selectedBankAccount = null;
        }
      }
    }
  }
})
