


































import Vue from "vue";
import {required} from "vuelidate/lib/validators";
import {mapActions, mapGetters} from "vuex";
import {loadStripe} from "@stripe/stripe-js";
import { errorMessage } from '@/utils/messages-handlers';
import { PaymentCardData } from '@/types';

export default Vue.extend({
  name: "add-payment-card-modal" as string,

  data() {
    return {
      cardName: "",
      cardNumber: "",
      validThruMonth: "",
      validThruYear: "",
      securityCode: "",
      isPaid: false,
      stripe: null,
      card: null,
      key: Math.floor(Math.random() * 100000)
    }
  },

  //@ts-ignore
  validations: {
    cardName: { required },
    cardNumber: { required },
    validThruMonth: { required },
    validThruYear: { required },
    securityCode: { required }
  },
  async mounted(): Promise<void> {
    this.CreateStripeElements()
  },

  computed: {
    ...mapGetters("companyModule", {
      companyId: "GET_COMPANY_ID"
    }),
    ...mapGetters("paymentModule", {
      stripeKey: "GET_STRIPE_PUBLISHABLE_KEY",
      isPaymentCardValid: "GET_IS_PAYMENT_CARD_VALID",
      paymentCardLoader: "GET_PAYMENT_CARD_LOADER",
      creditCards: "GET_PAYMENT_CARDS",
    }),
    lan(){
      return this.$i18n.locale
    }
  },

  methods: {
    ...mapActions("paymentModule", {
      addPaymentCard: "ADD_PAYMENT_CARD",
      setPaymentCardLoader: "SET_PAYMENT_CARD_LOADER",
      attachPaymentMethodToCustomer: "ATTACH_PAYMENT_METHOD_TO_CUSTOMER",
      setPaymentCards: "FETCH_PAYMENT_CARDS",
    }),
    async addNewPaymentCard(cardToken: string): Promise<void> {
      const payload: object = {
        venueOwnerId: this.companyId,
        paymentMethodToken: cardToken
      }

      await this.addPaymentCard(payload);

      if (this.isPaymentCardValid) this.$bvModal.hide("add-new-card");
    },
    async createStripeCardToken(): Promise<void> {
      this.setPaymentCardLoader(true);

      try {
        const { error, token } = await this.stripe.createToken(this.card);

        if (error) {
          errorMessage(error.message, 6000);
        }

        await this.addNewPaymentCard(token.id);
        await this.setPaymentCards();
        
        if (this.creditCards.length > 0) {
          let newCreditCard: PaymentCardData = this.creditCards[0];
          this.creditCards.map(creditCard => {
            if (creditCard.id > newCreditCard.id) {
              newCreditCard = creditCard;
            }
          });

          await this.attachPaymentMethodToCustomer({
            stripeCustomerId: newCreditCard.stripeCustomerId,
            stripeCardId: newCreditCard.stripeCardId,
          });
        }

        this.card.clear();
      } catch (e) {
        this.setPaymentCardLoader(false);
      }
    },
   async CreateStripeElements(): Promise<void>{
     try {
      this.stripe = await loadStripe(this.stripeKey, {locale: this.$i18n.locale});

      const elements = this.stripe.elements();
      const style = {
        base: {
          color: '#32325d',
          fontFamily: '"Montserrat", "Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '20px',
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      };
      this.card = elements.create('card', {hidePostalCode: true, style: style});
      this.card.mount('#card-element' + this.key);
    } catch (e) {

    }
    }
  },
  watch: {
      lan(newValue) {
        this.CreateStripeElements()
      }
  }
})
