













































































import Vue from "vue";
import {required} from "vuelidate/lib/validators";
import {mapActions, mapGetters} from "vuex";
import i18n from "@/i18n/i18n";
import * as ibantools from "ibantools";

export default Vue.extend({
  name: "add-bank-account-modal" as string,

  data() {
    return {
      bankName: "",
      bankIban: "",
      swiftCode: "",
      isSubmitted: false,
      paymentLoader: false,
      key: Math.floor(Math.random() * 100000)
    }
  },

  //@ts-ignore
  validations: {
    bankName: { required },
    bankIban: { required },
    swiftCode: {
      checkSwift(swiftCode) {
        return ibantools.isValidBIC(swiftCode);
      }
    }
  },

  async mounted(): Promise<void> {

  },

  computed: {
    ...mapGetters("companyModule", {
      companyId: "GET_COMPANY_ID"
    }),
    ...mapGetters("paymentModule", {
      ibanError: "GET_IBAN_ERROR"
    }),
    title(){
      return i18n.t('Add new bank account')
    }
  },

  methods: {
    ...mapActions("paymentModule", {
      addBankAccount: "ADD_BANK_ACCOUNT"
    }),
    async addNewBankAccount(): Promise<void> {
      this.isSubmitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) return;

      const payload: object = {
        bankName: this.bankName,
        bankCode: this.swiftCode,
        iban: this.bankIban,
        venueOwnerId: this.companyId,
      }

      await this.addBankAccount(payload);
      this.isSubmitted = false;
      this.bankName = "";
      this.bankIban = "";
      this.swiftCode = "";
      this.$bvModal.hide("add-new-bank-account");
    }
  }
})
